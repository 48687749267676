import React from 'react';
import PropTypes from 'prop-types';

import {withStyles} from '@material-ui/core/styles';
import withRoot from '../withRoot';

import {Route, Switch} from "react-router-dom";

import AppToolbar from "../components/AppToolbar";

import Home from "../pages/Home";
import WhyDrift from "../pages/WhyDrift";
import Curriculum from "../pages/Curriculum";
import Instructors from "../pages/Instructors";
import OurCars from "../pages/OurCars";
import Media from "../pages/Media";
import Register from "../pages/Register";
import Contact from "../pages/Contact";

import "./App.scss";

const styles = theme => ({
    root: {
        fontFamily: "Roboto"
    },

    content: {
        padding: "32px",
        marginTop: "40px",
    }
});

class App extends React.Component {
    state = {
        open: false,
    };

    handleClose = () => {
        this.setState({
            open: false,
        });
    };

    handleClick = () => {
        this.setState({
            open: true,
        });
    };

    render() {
        const { classes } = this.props;
        const { open } = this.state;

        return (
            <main className={classes.root}>
                <AppToolbar/>

                <section className={classes.content}>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/why-drift" component={WhyDrift} />
                        <Route exact path="/curriculum" component={Curriculum} />
                        <Route exact path="/instructors" component={Instructors} />
                        <Route exact path="/our-cars" component={OurCars} />
                        <Route exact path="/media" component={Media} />
                        <Route exact path="/register" component={Register} />
                        <Route exact path="/contact" component={Contact} />
                    </Switch>
                </section>
            </main>
    );
    }
}

App.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(App));