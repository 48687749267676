import React, {useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import './Pages.scss';

const styles = theme => ({
    root: {
        float: "right"
    },
});

const Register = (props) => {
    const { classes } = props;

    useEffect(() => {
        document.body.className = "register";
    });

    return (
        <section className={classes.root}>
            <h1 className="title uppercase intro-h1">Contacting Us</h1>

            <div className="content">
                <p>
                </p>
            </div>
        </section>
    );
};

export default withStyles(styles)(Register);