import React, {useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        float: "right"
    },
});

const WhyDrift = props => {
    const { classes } = props;

    useEffect(() => {
        document.body.className = "why-drift";
    });

    return (
        <section className={classes.root}>
            <h1 className="title uppercase intro-h1">Why drift?</h1>

            <div className="content">
                <h2 className="uppercase">I'm a track junkie. Not a drifter.</h2>
                <p>
                    We teach car control at the limits of traction. Are your car control skills improving
                    at the same rate as your HPDE skills?
                </p>
            </div>
        </section>
    );
};

export default withStyles(styles)(WhyDrift);