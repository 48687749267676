import React, {useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        float: "right"
    },
});

const Curriculum = props => {
    const { classes } = props;

    useEffect(() => {
        document.body.className = "curriculum";
    });

    return (
        <section className={classes.root}>
            <h1 className="title uppercase intro-h1">Your Curriculum</h1>

            <div className="content outline">
                <p>Did you know there are different types of spins? Do you understand the balance between steering
                    angle, wheel speed, and vehicle speed? We can help you.
                </p>

                <p>We will teach you the the science behind spins, what causes them, and how to recover from them. More
                    importantly, the experience you gain will allow you to analyze your own spins.</p>

                <p>
                    It starts with a classroom session and follow up with a live demonstration. Your turn. Hop in our
                    drift car and put the classroom session into practice.
                </p>

                <p>
                    Once your time is up, you can continue to hone your skill in your own car. If you have any
                    questions, an instructor is always available.
                </p>
            </div>
        </section>
    );
};

export default withStyles(styles)(Curriculum);