import React, {useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import './Pages.scss';

const styles = theme => ({
    root: {
        float: "right"
    },
});

const OurCars = (props) => {
    const { classes } = props;

    useEffect(() => {
        document.body.className = "cars";
    });

    return (
        <section className={classes.root}>
            <h1 className="title uppercase intro-h1">Our Drift Cars</h1>

            <div className="content the-cars">
                <p>
                    We stack the cards in your favor. Learning to control a car at the limit is hard work. Your
                    registration includes access to our drift cars. These cars are set up to slide, and they do it well.
                    This allows you to focus on learning the task at hand.
                </p>
            </div>
        </section>
    );
};

export default withStyles(styles)(OurCars);