import React, {useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import './Pages.scss';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    root: {
        float: "right"
    },
});

const Media = (props) => {
    const { classes } = props;

    useEffect(() => {
        document.body.className = "media";
    });

    return (
        <section className={classes.root}>
            <h1 className="title uppercase intro-h1">Media</h1>

            <Grid container spacing={16} >
                <Grid item xs={4}>
                    <div className="mdl-card mdl-shadow--2dp">
                        <div className="mdl-card__title">
                            <iframe type="text/html" frameBorder="0"
                                    src="https://www.youtube.com/embed/4GkYABndvJw"></iframe>
                        </div>

                        <div className="mdl-card__supporting-text">
                            &ldquo;What do you mean, you don't lift?&rdquo; Taka gives a demo ride in the 240sx drift
                            car after lunch.
                        </div>
                    </div>
                </Grid>

                <Grid item xs={4}>
                    <div className="mdl-card mdl-shadow--2dp">
                        <div className="mdl-card__title">
                            <iframe type="text/html" frameBorder="0"
                                    src="https://www.youtube.com/embed/76zwqwOd3Ug"></iframe>
                        </div>

                        <div className="mdl-card__supporting-text">
                            Prior to our event, Marek had never stepped foot on a race track. Watch him learn
                            how to initiate and manage oversteer.
                        </div>
                    </div>
                </Grid>

                <Grid item xs={4}>
                    <div className="mdl-card mdl-shadow--2dp">
                        <div className="mdl-card__title">
                            <iframe type="text/html" frameBorder="0"
                                    src="https://www.youtube.com/embed/qiQGwcizeIc"></iframe>
                        </div>

                        <div className="mdl-card__supporting-text">
                            After working at it for two days, Florin nails the counter steer doughnuts in his BRZ!
                        </div>
                    </div>
                </Grid>
            </Grid>
        </section>
    );
};

export default withStyles(styles)(Media);