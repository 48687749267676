import React from 'react';
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    root: {
        background: "rgba(255,255,255,0.8)",
        margin: "16px",
        padding: "16px",
        textAlign: "right",
    },

    links: {
        margin: "0 13px",
    }
});

const AppToolbar = (props) => {
    const { classes } = props;

    return (
        <header className={classes.root}>
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <nav className={classes.links}>
                        <Link className={classes.links} to="/">Home</Link>
                        <Link className={classes.links} to="/why-drift">Why drift?</Link>
                        <Link className={classes.links} to="/curriculum">Curriculum</Link>
                        <Link className={classes.links} to="/instructors">Instructors</Link>
                        <Link className={classes.links} to="/our-cars">Our cars</Link>
                        <Link className={classes.links} to="/media">Media</Link>
                        <Link className={classes.links} to="/register">How to Register</Link>
                        <Link className={classes.links} to="/contact">Contact</Link>
                    </nav>
                </Grid>
            </Grid>
        </header>
    );
};

AppToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppToolbar);
