import React, {useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import './Pages.scss';

const styles = theme => ({
    root: {
        float: "right"
    },

    video: {
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: "-99",
        height: "100%",
        width: "100%",

    }
});

const Home = (props) => {
    const { classes } = props;

    useEffect(() => {
        document.body.className = "home";
    });

    return (
        <section className={classes.root}>
            <div className={classes.video}>
                <iframe frameBorder="0" height="100%" width="100%"
                        src="https://youtube.com/embed/B5xdb8byqxM?autoplay=1&controls=0&showinfo=0&autohide=1">
                </iframe>
            </div>

            <h1 className="title uppercase intro-h1">Car control. At the limit.</h1>

            <div className="content info-blurb">
                <p>
                    Have you always been taught to come off the gas to prevent a spin?
                </p>

                <p>
                    Did you know there are different types of spins?
                    Do you understand the balance between steering angle, wheel speed, and vehicle speed?
                </p>

                <p>
                    We will teach you the the science behind spins, what causes them, and how to recover from them.
                    More importantly, your experience will allow you to analyze your own spins.
                </p>
            </div>
        </section>
    );
};

export default withStyles(styles)(Home);