import React, {useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import taka from "../images/static/aono.png";
import yoshie from "../images/static/shuyama.png";
import jon from "../images/static/ng.png";
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
});

const Instructors = props => {
    const { classes } = props;

    useEffect(() => {
        document.body.className = "instructors";
    });

    return (
        <section className="staff">
            <h1 className="title uppercase intro-h1">Our Staff</h1>

            <Grid container spacing={16} >
                <Grid item xs>
                    <div className="mdl-card mdl-shadow--2dp">
                        <div className="mdl-card__title aono">
                            <img src={taka} className="avatar" />
                        </div>

                        <div className="mdl-card__supporting-text">
                            <h2>Taka Aono</h2>
                            <ul>
                                <li>SCCA SOLO II National Champion</li>
                                <li>SCCA PROSOLO National Champion</li>
                                <li>Formula D compeitor and ProAM Judge</li>
                            </ul>
                        </div>
                    </div>
                </Grid>

                <Grid item xs>
                    <div className="mdl-card mdl-shadow--2dp">
                        <div className="mdl-card__title shuyama">
                            <img src={yoshie} className="avatar" />
                        </div>

                        <div className="mdl-card__supporting-text">
                            <h2>Yoshie Shuyama</h2>

                            <ul>
                                <li>2-time SCCA SOLO II National Champion</li>
                                <li>Multiple PROSOLO national wins</li>
                                <li>First female driver to compete in Formula D</li>
                            </ul>
                        </div>
                    </div>
                </Grid>

                <Grid item xs>
                    <div className="mdl-card mdl-shadow--2dp">
                        <div className="mdl-card__title ng">
                            <img src={jon} className="avatar" />
                        </div>

                        <div className="mdl-card__supporting-text">
                            <h2>Jonathan Ng</h2>

                            <ul>
                                <li>Audi, BMW, Porsche National Instructor</li>
                                <li>DriftSchoolUSA Graduate</li>
                                <li>3rd, NASA NorCal PTE</li>
                            </ul>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </section>
    );
};

export default withStyles(styles)(Instructors);